import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager", webpackExports: ["CompositionRootProvider"] */ "/app/src/components/composition-root-provider/CompositionRootProvider.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/container/styles.module.scss");
;
import(/* webpackMode: "eager" */ "/app/src/components/footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/_lib/components/desktop-version/main-header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/_lib/components/desktop-version/menu/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/header/_lib/components/desktop-version/styles.module.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/_lib/components/desktop-version/user-settings/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/header/_lib/components/mobile-version/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/header/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryClientComponent"] */ "/app/src/components/query-client-provider/QueryClientProvider.tsx");
